/** @jsx jsx */
import Helmet from 'react-helmet'
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import Button from '../components/Button'
import Marked from 'marked'
import '../pages/styles.css'

const Post = ({ pageContext }) => {
    const { post } = pageContext
    const content = Marked(post.content == '' ? '<br><br><p align="center">内容正在紧张筹备中，敬请谅解</p>' : post.content)

    return (
        <Layout>
            <Helmet>
                <title>【攻略】{post.title} - FDA助手</title>
            </Helmet>
            <div
                sx={{
                    paddingY: 9,
                    paddingX: 5,
                }}
            >
                <h1
                    sx={{
                        margin: 0,
                        fontSize: 6,
                        fontWeight: 'normal',
                        textAlign: 'center',
                    }}
                >
                    {post.title}
                </h1>
                <div
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 5,
                        fontSize: 3,
                    }}
                >
                    {/* <div sx={{ backgroundColor: '#fff', padding: 5, fontSize: 2, }}>{post.excerpt}</div> */}
                    <div
                        dangerouslySetInnerHTML={{ __html: content }}
                        className="app"
                    />
                    <Button
                        as="a"
                        href="/"
                        sx={{ display: 'block', width: '50%', variant: 'buttons.outline', marginTop: 10, marginX: 'auto' }}
                    >
                        返回首页
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export default Post